<template>
    <div v-if="dialogVisible" class="modal-mask">
        <div class="custom-modal-wrapper">
            <div class="custom-modal-container">
                <div class="custom-modal-custom-header">
                    <a href="javascript:void(0)" @click="close()">
                        <svg style="float: right;" width="18" height="18" viewBox="0 0 18 18" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 1L1 17" stroke="black" stroke-width="2"/>
                            <path d="M1 1L17 17" stroke="black" stroke-width="2"/>
                        </svg>
                    </a>
                </div>
                <div class="custom-modal-body">
                    <div class="text-center">
                        <span class="message">
                            Ваш поліс успішно оформлено
                        </span>
                    </div>
                    <div class="policy-link">
                        <span class="link text-center" style="margin: auto 20px auto 20px;">
                        </span>
                        <span class="link text-center" style="margin: auto;">
                            {{ policyLink }}
                        </span>
                        <a v-clipboard="this.policyLink" style="margin: auto 20px auto 20px;" href="javascript:void(0)">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.75 0C1.28587 0 0.840752 0.184375 0.512563 0.512563C0.184375 0.840752 0 1.28587 0 1.75V11.25C0 12.216 0.784 13 1.75 13H3.25C3.44891 13 3.63968 12.921 3.78033 12.7803C3.92098 12.6397 4 12.4489 4 12.25C4 12.0511 3.92098 11.8603 3.78033 11.7197C3.63968 11.579 3.44891 11.5 3.25 11.5H1.75C1.6837 11.5 1.62011 11.4737 1.57322 11.4268C1.52634 11.3799 1.5 11.3163 1.5 11.25V1.75C1.5 1.6837 1.52634 1.62011 1.57322 1.57322C1.62011 1.52634 1.6837 1.5 1.75 1.5H11.25C11.3163 1.5 11.3799 1.52634 11.4268 1.57322C11.4737 1.62011 11.5 1.6837 11.5 1.75V3.25C11.5 3.44891 11.579 3.63968 11.7197 3.78033C11.8603 3.92098 12.0511 4 12.25 4C12.4489 4 12.6397 3.92098 12.7803 3.78033C12.921 3.63968 13 3.44891 13 3.25V1.75C13 1.28587 12.8156 0.840752 12.4874 0.512563C12.1592 0.184375 11.7141 0 11.25 0H1.75ZM6.75 5C6.28587 5 5.84075 5.18437 5.51256 5.51256C5.18437 5.84075 5 6.28587 5 6.75V16.25C5 17.216 5.784 18 6.75 18H16.25C16.7141 18 17.1592 17.8156 17.4874 17.4874C17.8156 17.1592 18 16.7141 18 16.25V6.75C18 6.28587 17.8156 5.84075 17.4874 5.51256C17.1592 5.18437 16.7141 5 16.25 5H6.75ZM6.5 6.75C6.5 6.6837 6.52634 6.62011 6.57322 6.57322C6.62011 6.52634 6.6837 6.5 6.75 6.5H16.25C16.3163 6.5 16.3799 6.52634 16.4268 6.57322C16.4737 6.62011 16.5 6.6837 16.5 6.75V16.25C16.5 16.3163 16.4737 16.3799 16.4268 16.4268C16.3799 16.4737 16.3163 16.5 16.25 16.5H6.75C6.6837 16.5 6.62011 16.4737 6.57322 16.4268C6.52634 16.3799 6.5 16.3163 6.5 16.25V6.75Z" fill="#009BEB"/>
                            </svg>
                        </a>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TourismSuccess',
    data() {
        return {
            policyLink: null,
            dialogVisible: false
        };
    },
    methods: {
        close(done) {
            this.policyLink = null;
            this.dialogVisible = false;
        },
        open: function (policyLink) {
            this.policyLink = policyLink;
            this.dialogVisible = true;
        }
    }
};
</script>


<style lang="scss" scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.custom-modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.custom-modal-container {
    .policy-link {
        width: 436px;
        height: 64px;
        background: #F6F7FB;
        border-radius: 5px;
        display: flex;
        @media (max-width: 992px) {
            width: 300px;
        }

        .link {
            margin: auto 0 auto 12px;
            white-space: nowrap;
            overflow: hidden;
            max-width: 67%;
            padding: 5px;
            text-overflow: ellipsis;

            @media (max-width: 992px) {
                margin: auto 0 auto 2px;
            }
        }
    }
    .message {
        width: 258px; height: 29px;font-family: Circe,serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 29px;
        text-align: center;
        color: #24282C;

        @media (max-width: 992px) {
            width: 258px;
            height: 21px;
            font-size: 17px;
            line-height: 20px;
        }
    }

    .mtsbu-icon {
        margin: auto 0 auto 20px;

        @media (max-width: 992px) {
            margin: auto 0 auto 5px;
        }
    }

    @media (max-width: 992px) {
        width:358px;
    }
    width: 516px;
    height: 217px;
    background: #FFFFFF;
    border-radius: 5px;
    margin: 0 auto;
    padding: 20px 30px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.custom-modal-custom-header h3 {
    margin-top: 0;
    color: #42b983;
}

.custom-modal-body {
    margin: 20px 0;
}

.modal-default-button {
    float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .custom-modal-container,
.modal-leave-active .custom-modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

</style>
